import styled from "styled-components";

// Type Scale: Musical Tritonic
// ----------
// f0 = 1em = 17px
// r = 2
// n = 3

export const HeaderLarge = styled.h1`
  margin-bottom: 2.52rem;
  font-size: 2.52rem;
  font-weight: 800;
  font-style: normal;
  line-height: 1.25;
  color: rgb(88, 96, 110);
`;

export const HeaderMedium = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.59rem;
  font-weight: 700;
  font-style: normal;
  line-height: 1.25;
  color: rgb(88, 96, 110);
`;

export const HeaderSmall = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.26rem;
  font-weight: 700;
  font-style: normal;
  line-height: 1.25;
  color: rgb(88, 96, 110);
`;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  color: rgb(51, 56, 64);
`;

export const CaptionBold = styled.p`
  font-size: 0.79rem;
  font-weight: 700;
  line-height: 1.25;
  color: rgb(51, 56, 64);
`;

export const CaptionMedium = styled.p`
  font-size: 0.79rem;
  font-weight: 600;
  line-height: 1.25;
  color: rgb(88, 96, 110);
`;

export const CaptionLight = styled.p`
  font-size: 0.852rem;
  font-weight: 400;
  line-height: 1.25;
  color: rgb(88, 96, 110);
`;
