import React from "react";
import styled from "styled-components";
import dreamlinksLogo from "images/dreamlinks-logo-type.svg";

const HomeContainer = styled.header.attrs({
  "aria-labelledby": "dreamlinks-logo",
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DreamlinksLogo = styled.img.attrs({
  id: "dreamlinks-logo",
  src: dreamlinksLogo,
  alt: "Dreamlinks home, a rainbow swirls around the Braille characters for d and l",
})`
  height: 2rem;
`;

const HomeLink = () => (
  <a href=".">
    <HomeContainer>
      <DreamlinksLogo />
    </HomeContainer>
  </a>
);

export default HomeLink;
